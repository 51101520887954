import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, forkJoin, Observable, Subscription, BehaviorSubject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { Webpage } from "../@core/data/webpage-data/webpage";
import { WebpageContent } from "../@core/data/backend-response/webpage-content";

import { UserNotificationService } from "./user-notification.service";
import { QAndAService } from "./q-and-a.service";
import { environment } from "../../environments/environment";

import {
  NbToastrService,
  NbComponentStatus,
  NbGlobalPosition,
  NbGlobalPhysicalPosition,
} from "@nebular/theme";
import { UserService } from "./users.service";
import { WebsocketService } from "./websocket.service";
import { ChatbotService } from "./chat-bot.service";

@Injectable({
  providedIn: "root",
})
export class WebsiteCrawlerService {
  private webpages: Webpage[] = [];
  private _webpagesSubject = new BehaviorSubject<Webpage[]>([]);

  // Base url
  private baseUrl = environment.backendUrl;
  private websocketUrl = environment.websocketUrl;
  // Backend baseurl
  private webcrawlerUrl = environment.webscraperUrl;
  // Bucket name
  private bucketName = environment.webscraperAssetsBucket;
  // localFolderName
  private localFolderName = "webPageScraper";

  // Processing flag
  private crawlingStatusSubject = new BehaviorSubject<boolean>(false);
  crawlingStatus$ = this.crawlingStatusSubject.asObservable();

  private socket;

  // Toaster variables
  destroyByClick = true;
  duration = 5000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;

  constructor(
    private http: HttpClient,
    private toasterService: NbToastrService,
    private notificationService: UserNotificationService,
    private qandaService: QAndAService,
    private userService: UserService,
    private websocketService: WebsocketService,
    private chatbotService: ChatbotService
  ) {
    this.chatbotService.curBotID$.subscribe({
      next: (botID) => {
        this.populateWebpages(botID);
        this._webpagesSubject.next(this.webpages);
      },
    });
  }

  initialize() {
    if (!this.socket) {
      this.socket = this.websocketService.getSocket();
    }
  }

  populateWebpages(botID) {
    // Make a GET request to the backend.
    this.http.get<any[]>(`${this.baseUrl}/website/initialize`).subscribe({
      next: (response) => {
        for (let website of response) {
          if (website.botID && website.botID === botID) {
            for (let faq of website?.faqs) {
              faq.sourceId = website.docid;
              faq.sourceType = "webpage";
              faq.source = website.webpageSource;
              this.qandaService.addQandA(faq);
            }
            this.webpages.push(website);
          }
        }
        // Emit the changes
        this._webpagesSubject.next(this.webpages);
      },
      error: (error) => {
        // Handle error from the backend.
        console.error(
          "Error fetching websites from the backend in initialization:",
          error
        );
      },
    });
  }

  clearWebpages() {
    this.webpages = [];
  }

  // Getter for the webpages observable
  get webpages$(): Observable<Webpage[]> {
    return this._webpagesSubject.asObservable();
  }

  // POST Methods
  submitUrl(urls: String[]) {
    // Set crawling status to true
    this.crawlingStatusSubject.next(true);

    const requests = urls.map(url => this.http.post(this.webcrawlerUrl, {
      companyWebsite: url,
      savingDirectory: this.bucketName,
      localFolderName: this.localFolderName,
      botID: this.chatbotService.getCurBotID(),
    }, { responseType: "text" }));

    let { observable, unsubscribe } = this.getWebcrawlerUpdate();

    // Start crawler knowledge update subscription
    observable.subscribe(async (data) => {
      this.webpages.push(data);
      this._webpagesSubject.next(this.webpages);
    });

    forkJoin(requests).subscribe(
      (responses) => {
        setTimeout(() => {
          this.crawlingStatusSubject.next(false);
          this.notificationService.showToast(
            "success",
            "Website Crawled",
            `URLs successfully crawled`
          );
          unsubscribe();
        }, 10000);
      },
      (error) => {
        this.crawlingStatusSubject.next(false);
        this.notificationService.showToast(
          "danger",
          "Something Went Wrong!",
          `${error}`
        );
        console.error('Error occurred while making webpage crawl request:', error);
        unsubscribe();
      }
    );
  }

  getWebcrawlerUpdate() {
    let webcrawlerDatasub = new Subject<any>();
    let endSub = new Subject<any>();

    this.socket.on("webcrawler-update", async (data) => {
      webcrawlerDatasub.next(data);
    });

    // Listen for the end of the subscription to remove the event listener
    webcrawlerDatasub.pipe(takeUntil(endSub)).subscribe();

    return {
      observable: webcrawlerDatasub.asObservable(),
      unsubscribe: () => {
        endSub.next(true); // Emit to end the subscription
        this.socket.off("webcrawler-update"); // Remove the event listener
      },
    };
  }

  getKnowledgeGeneration() {
    this.socket.on("qa-generated-webpage", async (data) => {
      this.webpages.find((webpage) => webpage.docid === data.docId).faqs =
        data.body;
      this.webpages.find(
        (webpage) => webpage.docid === data.docId
      ).qaGenerationStatus = "Generated";
      this.qandaService.addQAs(data.body);
      this._webpagesSubject.next(this.webpages);
    });
  }

  getWebpageDeletionUpdate() {
    let webpageDataSub = new Subject<any>();
    let endSub = new Subject<any>();

    this.socket.on("webpage-deletion-update", async (data) => {
      webpageDataSub.next(data);
    });

    // Listen for the end of the subscription to remove the event listener
    webpageDataSub.pipe(takeUntil(endSub)).subscribe();

    return {
      observable: webpageDataSub.asObservable(),
      unsubscribe: () => {
        endSub.next(true); // Emit to end the subscription
        this.socket.off("webpage-deletion-update"); // Remove the event listener
      },
    };
  }

  addWebpage(webpage) {
    // Check if there's already an element with the same 'fileSource'
    const existingWebpage = this.webpages.find(
      (webpage) => webpage.docid === webpage.docid
    );

    // If there's no match, push fileData into the array
    if (!existingWebpage) {
      this.webpages.push(webpage);
      this._webpagesSubject.next(this.webpages);
    }
  }

  addQA(knowledgeData) {
    // Find the webpage that matches the sourceId of the knowledgeData
    const webpage = this.webpages.find(
      (webpage) => webpage.docid === knowledgeData.sourceId
    );

    if (webpage) {
      knowledgeData.source = webpage.webpageSource;
      if (!webpage.faqs) {
        webpage.faqs = [];
        // Add the knowledgeData object to the webpage's faqs array
        webpage.faqs.push(knowledgeData);
        // Update status locally
        webpage.qaGenerationStatus = "Pending Approval";
      } else {
        webpage.faqs.push(knowledgeData);
      }
      this._webpagesSubject.next(this.webpages);
    }
  }

  updateQA(knowledgeData) {
    // Find the webpage that matches the sourceId of the knowledgeData
    const webpage = this.webpages.find(
      (webpage) => webpage.docid === knowledgeData.sourceId
    );

    if (webpage) {
      // Search for an object in the webpage's faqs array that matches the qid field of the knowledgeData object
      const existingIndex = webpage.faqs.findIndex(
        (faq) => faq.qid === knowledgeData.qid
      );

      webpage.faqs[existingIndex] = {
        ...webpage.faqs[existingIndex],
        ...knowledgeData,
      };

      // Emit changes to webpages
      this._webpagesSubject.next(this.webpages);
    }
  }

  removeWebpageLocal(toRemove) {
    // Remove the object from this.fileContents where the docid field matches data.docid
    this.webpages = this.webpages.filter(
      (file) => file.docid !== toRemove.docid
    );

    // Emit changes to subscribers
    this._webpagesSubject.next(this.webpages);
  }

  removeWebpageQALocal(QAToRemove) {
    // Find the object in this.fileContents where the docid matches knowledgeData.sourceId
    const webpage = this.webpages.find(
      (webpage) => webpage.docid === QAToRemove.sourceId
    );

    if (webpage) {
      // If a match is found, remove the object from the faqs array where the qid field equals QAToRemove.qid
      const index = webpage.faqs.findIndex((faq) => faq.qid === QAToRemove.qid);
      if (index !== -1) {
        // If the object with key QAToRemove.qid exists, delete it
        webpage.faqs.splice(index, 1);
        this._webpagesSubject.next(this.webpages);
      }
    }
    return;
  }

  updateWebpageDeletionStatus(newWebpage) {
    const index = this.webpages.findIndex(
      (webpage) => webpage.docid === newWebpage.docid
    );

    if (index !== -1) {
      this.webpages[index].deleteStatus = newWebpage.deleteStatus;
      delete this.webpages[index].processPendingDeletion;
    }

    // Emit changes to subscribers
    this._webpagesSubject.next(this.webpages);
  }

  getWebpageContent(dataLocation: string) {
    const payload = { dataLocation }; // Wrap into an object
    return this.http.post<WebpageContent>(
      `${this.baseUrl}/knowledge/content`,
      payload
    );
  }

  deleteWebpage(
    webpage: any,
    deleteDocFaqs: boolean,
    deleteDocLlm: boolean
  ): Observable<any> {
    const docIDs = [webpage.docid];
    const randomUUID = uuidv4();
    const body = {
      id: randomUUID,
      docIDs: docIDs,
      delete_doc_faqs: deleteDocFaqs,
      delete_doc_llm: deleteDocLlm,
    };

    return this.http.post(`${this.baseUrl}/knowledge/remove`, body);
  }

  undoDelete(webpage: any): Observable<any> {
    const docIDs = [webpage.docid];
    const randomUUID = uuidv4();
    const body = {
      id: randomUUID,
      docIDs: docIDs,
      delete_doc_faqs: true,
      delete_doc_llm: false,
    };

    return this.http.post(`${this.baseUrl}/knowledge/remove/undo`, body);
  }
}
